import React, {useState, useEffect} from 'react';
import ReactGA from 'react-ga4';

import Footer from './components/Footer.js';
import EmailForm from './components/EmailForm.js';
import Thankyou from './components/Thankyou.js';
const TRACKING_ID = "G-NPK2GZHFLE";


function App() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  function submitForm() {
    setIsSubmitted(true);
  }

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
    }, [])

  return (
    
  <div>
    <div className="w3-display-topleft w3-padding-large w3-xlarge">GQUITY</div>
    <div className="w3-display-middle">
      <p className="w3-large w3-center"><img src="/assets/brand/new_logo1.png" alt="gquity_logo" style={{alignItems: "inherit", width: "20%", height: "30%"}}/></p>
      <h1 className="w3-xxlarge w3-center">Empowering financial success with innovation.</h1>
      <hr className="w3-border"></hr>
    
      { isSubmitted ?
                  <Thankyou/> :
                  <EmailForm submitForm={submitForm}/>
              }
    </div>
  
    <Footer/>
  </div>
  );
}

export default App;
